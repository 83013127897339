import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { StorageService } from '../../../Services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  readonly currentVersion = environment.versionNo || '0.0.7';
  private readonly storageKey = 'versionKey';
  constructor(
    public sS: StorageService,
    public authS: AuthenticationService
  ) {
    this.checkCurrentVersion();
  }

  checkCurrentVersion() {
    const localVersion = this.getLocalVersion();
    if (localVersion && localVersion != this.currentVersion) {
      this.setLocalVersion();
      this.authS.logout();
    } else {
      this.setLocalVersion();
    }
  }
  getLocalVersion() {
    return this.sS.getItem<string>(this.storageKey);
  }
  setLocalVersion() {
    environment.versionNo = this.currentVersion;
    this.sS.saveItem(this.storageKey, this.currentVersion);
  }
}
